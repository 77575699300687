import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      title,
      altTheme,
      bannerOverline,
      bannerText,
      bannerLinks,
      modularBlocks,
    },
  },
}) => (
  <Layout altTheme={altTheme}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        overline={bannerOverline}
        heading={title}
        text={bannerText}
        links={bannerLinks}
        altTheme={altTheme}
      />
      <ModularBlocks items={modularBlocks} altTheme={altTheme} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      altTheme
      bannerOverline
      bannerText
      bannerLinks {
        id
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...BrandsModularBlockFragment
        ...CtaCardsModularBlockFragment
        ...CtaStripModularBlockFragment
        ...ContactFormInformationModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentCardsCarouselModularBlockFragment
        ...ContentCardsModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedInformationCardsModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageContentCardsModularBlockFragment
        ...ImageTestimonialsModularBlockFragment
        ...ImageCarouselModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...QuoteModularBlockFragment
        ...StatisticsModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
